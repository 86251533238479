import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Text = makeShortcode("Text");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <Text sx={{
      fontSize: [4, 5, 6],
      fontWeight: `bold`,
      color: `rgba(0,0,0,0)`,
      background: `linear-gradient(90deg,#fc5c7d,#6a82fb)`,
      WebkitBackgroundClip: `text`,
      textDecoration: `none`
    }} mdxType="Text">
  Welcome!
    </Text>
    <p>{`I'm Mario Vilar, a 21 year old student currently living in Sitges, Barcelona. I'm pursuing a double degree in Mathematics and Computer Science at University of Barcelona.`}</p>
    <p>{`Here you have `}<a parentName="p" {...{
        "href": "https://www.vilar.dev/about"
      }}>{`a brief introduction about myself`}</a>{`, `}<a parentName="p" {...{
        "href": "https://www.vilar.dev/notes"
      }}>{`what am I doing at university`}</a>{` and `}<a parentName="p" {...{
        "href": "https://www.vilar.dev/blog"
      }}>{`my interests`}</a>{`. If you couldn't find what you were looking for, my `}<a parentName="p" {...{
        "href": "mailto:mvr7@outlook.es"
      }}>{`digital door is always open`}</a>{`.`}</p>
    <hr></hr>
    <p><strong parentName="p">{`DISCLAIMER!`}</strong>{` Keep in mind that some of the posts might not be in English, specially those which aren't about technology. `}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      